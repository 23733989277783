import {useCallback, useEffect, useState} from "react";
import SessionTable from "./SessionTable";
import {request} from "../timeTracking";
import authStorage from "../auth_storage";
import {Link} from "react-router-dom";

function Sessions() {
    const [sessions, setSessions] = useState([])
    const [skip, setSkip] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        async function fetchSessions() {
            try {
                setLoading(true)
                const res = await request('/api/v1/time-tracking/sessions', authStorage.get())
                const data = await res.json()
                setSessions(data.payload.sessions)
                setSkip(data.payload.skip)
            } catch (e) {
                console.error(e)
            } finally {
                setLoading(false)
            }
        }

        fetchSessions()
    }, []);
    const next = useCallback(async () => {
        try {
            const res = await request(`/api/v1/time-tracking/sessions?skip=${skip}`, authStorage.get())
            const data = await res.json()
            setSessions([...sessions, ...data.payload.sessions])
            setSkip(data.payload.skip)
        } catch (e) {
            console.error(e)
        }
    }, [sessions, skip])
    return (
        <div className="container">
            <div className="row">
                <div className="col-2 col-lg-1">
                    <div className="my-3">
                        <Link to={"/"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                                 className="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
                            </svg>
                        </Link>
                    </div>
                </div>

                <div className={"col-10 col-lg-11 align-content-center"}>
                    <h1>Sessions:</h1>
                </div>
            </div>
            <div className="row justify-content-between">
                <div className="col-12">
                {!loading && sessions.length === 0 && <p className="w-100 text-center">No sessions</p>}
                    {loading && <p className={"w-100 text-center"}>Loading...</p>}
                    {!loading && sessions.length > 0 && <SessionTable sessions={sessions} next={next}/>}
                </div>
            </div>
        </div>
    );
}

export default Sessions;