import './App.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.min.css'
import {Outlet, Route, Routes,} from "react-router-dom"
import {useCallback, useRef, useState,} from "react"
import {loader as appLoader} from './TimeTracker'
import ChangePin, {loader as changePinLoader} from './ChangePin'
import TimeTracker from "./TimeTracker";
import Login from "./Login";
import Sessions from "./sessions/Sessions";
import SelectProject from "./sessions/SelectProject";


function App() {
    const [message, setMessage] = useState("")
    const to = useRef("")

    const showMessage = useCallback((msg, timeout = 3000) => {
        if (to.current) {
            clearTimeout(to.current)
        }
        setMessage(msg)
        to.current = setTimeout(() => setMessage(""), timeout)
    }, [to])

    const clearMessage = useCallback(() => {
        setMessage("")
    }, [setMessage])

    return (
        <Routes>
            <Route path="/" element={<Layout message={message} clearMessage={clearMessage}/>}>
                <Route index element={<TimeTracker showMessage={showMessage} clearMessage={clearMessage}/>} loader={appLoader}/>
                <Route path="/login" element={<Login showMessage={showMessage} clearMessage={clearMessage}/>}/>
                <Route path="/change-pin" element={<ChangePin showMessage={showMessage} clearMessage={clearMessage}/>} loader={changePinLoader}/>
                <Route path="/sessions" element={<Sessions/>}/>
                <Route path="/sessions/:id/select-project" element={<SelectProject/>}/>
            </Route>
        </Routes>
    )
}

function Layout({message, clearMessage}) {
    return (
        <>
            <Outlet/>
            {message !== "" && <div className="message d-flex justify-content-center">
                <div className="alert alert-warning alert-dismissible" role="alert">
                    {message}
                    <button type="button" className="btn-close" onClick={clearMessage}></button>
                </div>
            </div>}
        </>
    );
}

export default App