import './TimeTracker.css'
import 'bootstrap/dist/css/bootstrap.css'
import {redirect, useNavigate} from "react-router-dom"
import {useCallback, useContext, useEffect, useState} from "react"
import {
    INCREASE_PROJECT_DURATION,
    ReducerContext,
    SET_PROJECTS,
    SET_PROJECT_DELTA_DURATION, SET_STATUS, SET_RECENT_PROJECTS, SET_SELECTED_PROJECT_ID,
} from "./reducer"
import authStorage from "./auth_storage"
import {
    startSession,
    getData,
    UnauthorizedError,
    getProjects,
    stopSession,
    SESSION_TYPE_PROJECT,
    SESSION_TYPE_CLEANING,
    SESSION_TYPE_CALLING,
    SESSION_TYPE_TECHNIK,
    SESSION_TYPE_NEW_LEAD
} from "./timeTracking"
import Projects from "./projects/Projects"
import Time from "./Time";
import {useInterval} from "./hooks";
import InfoPanel from "./InfoPanel";
import Popup from "./Popup";
import preferenceStorage from "./preferenceStorage";

const take = 100

function TimeTracker({showMessage, clearMessage}) {
    const {state, dispatch} = useContext(ReducerContext)
    const [btnDisabled, setBtnDisabled] = useState(true)
    const [time, setTime] = useState(0)
    const [loading, setLoading] = useState(true)
    const [skip, setSkip] = useState(0)
    const [isOwnProjects, setIsOwnProjects] = useState(false)
    const [isSevenDays, setIsSevenDays] = useState(false)
    const [showRecentProjects, setShowRecentProjects] = useState(preferenceStorage.getShowRecentProjectsValue())
    const navigate = useNavigate()

    useEffect(() => {
        loadStatus().catch(e => {
            showMessage(e.message || 'error')
        })
        updateProjects(isOwnProjects, isSevenDays, showRecentProjects).catch(e => {
            showMessage(e.message || 'error')
        })
    }, [])

    const updateStatus = async () => {
        try {
            const data = await getData(authStorage.get())
            if (data.status === 401) {
                authStorage.clear()
                navigate('/login')
                return
            }
            if (data.status === 400) {
                if (data.refresh) {
                    window.location = '/'
                }
                return
            }
            if (state.status.statusCode !== data.payload.statusCode) {
                clearMessage()
            }
            dispatch({type: SET_STATUS, payload: data.payload})
            if (data.payload.statusCode === 0) {
                showMessage("Please clock-in in the Crewmeister app before using this app.", 300000)
                setBtnDisabled(true)
            } else if (data.payload.statusCode === 2) {
                showMessage("You are on break. Continue work on Crewmeister app before using this app.", 300000)
                setBtnDisabled(true)
            } else {
                setBtnDisabled(false)
            }
        } catch (e) {
            console.error(e)
        }
    }

    useInterval(updateStatus, 15000)

    const loadStatus = async () => {
        try {
            setLoading(true)
            setBtnDisabled(true)
            const data = await getData(authStorage.get())
            if (data.status === 401) {
                authStorage.clear()
                navigate('/login')
                return
            }
            if (data.status === 400) {
                if (data.refresh) {
                    window.location = '/'
                }
                return
            }
            dispatch({type: SET_STATUS, payload: data.payload})
            if (data.payload.statusCode === 0) {
                showMessage("Please clock-in in the Crewmeister app before using this app.", 300000)
            } else if (data.payload.statusCode === 2) {
                showMessage("You are on break. Continue work on Crewmeister app before using this app.", 300000)
            } else {
                setBtnDisabled(false)
            }
        } catch (e) {
            console.error(e)
            showMessage(e.toString())
        } finally {
            setLoading(false)
        }
    }

    const handleSessionResponse = useCallback(async data => {
        if (data.status === 401) {
            authStorage.clear()
            clearMessage()
            navigate('/login')
            return
        }
        if (data.status === 400) {
            if (data.refresh) {
                window.location = '/'
            }
            showMessage("something went wrong while loading data from server")
            return
        }
        dispatch({type: SET_STATUS, payload: data.payload.status})
        const previousSession = data.payload.previousSession
        if (previousSession && previousSession.Subproject) {
            dispatch({type: SET_PROJECT_DELTA_DURATION, payload: {id: previousSession.Subproject.Project.id, duration: 0}})
            const project = state.projects.find((project) => project.id === previousSession.Subproject.Project.id)
            if (project) {
                dispatch({
                    type: INCREASE_PROJECT_DURATION,
                    payload: {id: project.id, duration: previousSession.seconds}
                })
            }
        }
    }, [clearMessage, dispatch, navigate, showMessage, state.projects])

    const onClickStopSession = async () => {
        try {
            if (btnDisabled) {
                return
            }
            setLoading(true)
            setBtnDisabled(true)
            const data = await stopSession(authStorage.get(), state.status.session.id)
            await handleSessionResponse(data)
        } catch (e) {
            showMessage(e.toString())
            console.error(e)
        } finally {
            setLoading(false)
            setBtnDisabled(false)
            setTime(0)
        }
    }

    const updateProjects = useCallback(async (isOwnProjects, isSevenDays, showRecentProjects) => {
        try {
            const payload = await getProjects(authStorage.get(), isOwnProjects, isSevenDays, 0, take, state.search, showRecentProjects)
            setSkip(payload.nextTake)
            dispatch({type: SET_PROJECTS, payload: payload.list})
            dispatch({type: SET_RECENT_PROJECTS, payload: payload.recentProjects})
        } catch (e) {
            if (e instanceof UnauthorizedError) {
                authStorage.clear()
                navigate('/login')
            }
        }
    }, [dispatch, navigate, state.search])



    const onClickSubproject = useCallback(async subprojectId => {
        try {
            if (btnDisabled || state.status?.statusCode !== 1 || (state.status?.session?.Subproject?.id === subprojectId)) {
                return
            }
            setLoading(true)
            setBtnDisabled(true)
            const data = await startSession(authStorage.get(), SESSION_TYPE_PROJECT, subprojectId)
            await handleSessionResponse(data)
            await updateProjects(isOwnProjects, isSevenDays, showRecentProjects)
            dispatch({type: SET_SELECTED_PROJECT_ID, payload: null})
        } catch (e) {
            console.error(e)
            showMessage(e.toString())
        } finally {
            setLoading(false)
            setBtnDisabled(false)
        }
    }, [btnDisabled, dispatch, handleSessionResponse, isOwnProjects, isSevenDays, showMessage, showRecentProjects, state.status?.session?.Subproject?.id, state.status?.statusCode, updateProjects])

    const onClickLogout = () => {
        authStorage.clear()
        dispatch({type: SET_STATUS, payload: null})
        setTime(0)
        clearMessage()
        navigate('/login')
    }

    const onShowRecentProjects = async (showRecentProjects = true) => {
        try {
            setLoading(true)
            setShowRecentProjects(showRecentProjects)
            preferenceStorage.saveShowRecentProjectsValue(showRecentProjects)
            await updateProjects(isOwnProjects, isSevenDays, showRecentProjects)
        } finally {
            setLoading(false)
        }
    }

    const onSevenDaysChanged = async e => {
        try {
            e.preventDefault()
            setLoading(true)
            setIsSevenDays(e.target.checked)
            await updateProjects(isOwnProjects, e.target.checked, showRecentProjects)
        } finally {
            setLoading(false)
        }
    }

    const onOwnProjectsChanged = async e => {
        try {
            e.preventDefault()
            setLoading(true)
            setIsOwnProjects(e.target.checked)
            await updateProjects(e.target.checked, isSevenDays, showRecentProjects)
        } finally {
            setLoading(false)
        }
    }

    const startTypeSession = async type => {
        try {
            if (btnDisabled || state.status?.session?.type === type) {
                return
            }
            setLoading(true)
            setBtnDisabled(true)
            const data = await startSession(authStorage.get(), type, null)
            await handleSessionResponse(data)
        } catch (e) {
            console.error(e)
            showMessage(e.toString())
        } finally {
            setLoading(false)
            setBtnDisabled(false)
        }
    }

    return (
        <div className="container-fluid p-0">
            <div className="row g-1 header m-0">
                <div className="col-4 col-lg-2">
                    <div className="hstack gap-1 d-flex justify-content-around w-100">
                        <button className="button " onClick={onClickStopSession}
                                disabled={btnDisabled || !(state.status?.session)}>
                            <img src="/images/clock-out.svg" className="" alt=""/>
                        </button>
                        <Popup placement="bottom-start">
                            {({setPopperElement, setReferenceElement, styles, attributes, isOpen}) => {
                                return (
                                    <>
                                        <button className="button" ref={setReferenceElement}>
                                            <img src="/images/work.svg" className="" alt=""/>
                                        </button>
                                        {isOpen && <div className={`list-group`}
                                                        ref={setPopperElement}
                                                        style={styles.popper}
                                                        {...attributes.popper}
                                        >
                                            <button type="button"
                                                    className="list-group-item list-group-item-action"
                                                    aria-current="true"
                                                    disabled={btnDisabled || state.status?.session?.type === SESSION_TYPE_CLEANING}
                                                    onClick={() => startTypeSession(SESSION_TYPE_CLEANING)}>
                                                <img src="/images/clean.svg" alt=""/>
                                                <span className="ms-1">Cleaning</span>
                                            </button>
                                            <button type="button"
                                                    className="list-group-item list-group-item-action"
                                                    aria-current="true"
                                                    disabled={btnDisabled || state.status?.session?.type === SESSION_TYPE_CALLING}
                                                    onClick={() => startTypeSession(SESSION_TYPE_CALLING)}>
                                                <img src="/images/call.svg" alt=""/>
                                                <span className="ms-1">Calling</span>
                                            </button>
                                            <button type="button"
                                                    className="list-group-item list-group-item-action"
                                                    aria-current="true"
                                                    disabled={btnDisabled || state.status?.session?.type === SESSION_TYPE_TECHNIK}
                                                    onClick={() => startTypeSession(SESSION_TYPE_TECHNIK)}>
                                                <img src="/images/technic.svg" alt=""/>
                                                <span className="ms-1">Technik</span>
                                            </button>
                                            <button type="button"
                                                    className="list-group-item list-group-item-action"
                                                    aria-current="true"
                                                    disabled={btnDisabled || state.status?.session?.type === SESSION_TYPE_NEW_LEAD}
                                                    onClick={() => startTypeSession(SESSION_TYPE_NEW_LEAD)}>
                                                <img src="/images/new_lead.svg" alt=""/>
                                                <span className="ms-1">New lead</span>
                                            </button>
                                        </div>}
                                    </>
                                )
                            }}
                        </Popup>
                    </div>
                </div>
                <div className="col-4 col-lg-8 d-flex align-items-center justify-content-center">
                    <Time loading={loading} status={state.status} time={time} setTime={setTime}/>
                </div>
                <div className="col-4 col-lg-2">
                    <div className="hstack gap-1 d-flex justify-content-around w-100">
                        <button className="button" onClick={onClickLogout}>
                            <img src="/images/logout.svg" className="" alt=""/>
                        </button>
                        <Popup placement="bottom-end">
                            {({setPopperElement, setReferenceElement, styles, attributes, isOpen}) => {
                                return (
                                    <>
                                        <button className="button" ref={setReferenceElement}>
                                            <img src="/images/menu.svg" className="" alt=""/>
                                        </button>
                                        {isOpen && <div className={`list-group`}
                                                        ref={setPopperElement}
                                                        style={styles.popper}
                                                        {...attributes.popper}>
                                            <button type="button"
                                                    className="list-group-item list-group-item-action"
                                                    aria-current="true"
                                                    onClick={() => onShowRecentProjects(!showRecentProjects)}
                                            >
                                                {showRecentProjects ? "Hide recent projects" : "Show recent projects"}
                                            </button>
                                            <button type="button"
                                                    className="list-group-item list-group-item-action"
                                                    aria-current="true"
                                                    onClick={() => navigate('/sessions')}
                                            >
                                                Sessions
                                            </button>
                                            <a type="button" href="/change-pin"
                                               className="list-group-item list-group-item-action"
                                               aria-current="true">
                                                PIN ändern
                                            </a>
                                        </div>}
                                    </>
                                )
                            }}
                        </Popup>
                    </div>
                </div>
            </div>
            <div className="row m-0">
                <div className="col-3 col-lg-2">
                    <div className="vstack gap-0 my-3">
                        <h6 className="checkbox-label text-center">+/- 7 Tage</h6>
                        <div className="form-check form-switch big-switch d-flex justify-content-center">
                            <input className="form-check-input" type="checkbox" id="check1" checked={isSevenDays}
                                   onChange={onSevenDaysChanged}/>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-lg-8 d-flex justify-content-center align-items-center">
                    <InfoPanel status={state.status}/>
                </div>
                <div className="col-3 col-lg-2">
                    <div className="vstack gap-0 my-3">
                        <h6 className="checkbox-label text-center">nur eigene</h6>
                        <div className="form-check form-switch big-switch d-flex justify-content-center">
                            <input className="form-check-input" type="checkbox" id="check2" checked={isOwnProjects}
                                   onChange={onOwnProjectsChanged}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center m-0">
                <div className="col-12 pt-1 px-0">
                    <Projects isOwnProjects={isOwnProjects} isSevenDays={isSevenDays} take={take} skip={skip}
                              setSkip={setSkip} showMessage={showMessage} onClickSubproject={onClickSubproject}
                              showRecentProjects={showRecentProjects}/>
                </div>
            </div>
        </div>
    )
}

export async function loader() {
    const token = authStorage.get()
    if (!token) {
        return redirect('/login')
    }
    return {}
}

export default TimeTracker