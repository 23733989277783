import {Link, useNavigate, useParams} from "react-router-dom";
import {useCallback, useEffect, useRef, useState} from "react";
import {getProjects, request} from "../timeTracking";
import authStorage from "../auth_storage";

function SelectProject() {
    const navigate = useNavigate()
    const [projects, setProjects] = useState([])
    const [isLeading, setIsLeading] = useState(false)
    const [working, setWorking] = useState(false)
    const [skip, setSkip] = useState(0)
    const [search, setSearch] = useState('')
    const {id} = useParams();
    useEffect(() => {
        async function fetchSessions() {
            try {
                setIsLeading(true)

                const res = await request('/api/v1/projects', authStorage.get())
                const data = await res.json()
                setProjects(data.payload.list)
                setSkip(data.payload.nextTake)
            } catch (e) {
                console.error(e)
            } finally {
                setIsLeading(false)
            }
        }

        fetchSessions()
    }, []);

    const next = useCallback(async () => {
        try {
            setIsLeading(true)
            const payload = await getProjects(authStorage.get(), false, false, skip, 100, search)
            setProjects([...projects, ...payload.list])
            setSkip(payload.nextTake)
        } catch (e) {
            console.error(e)
        } finally {
            setIsLeading(false)
        }
    }, [projects, skip])

    const select = async (projectId) => {
        try {
            setWorking(true)
            const res = await request(`/api/v1/time-tracking/sessions/${id}`, authStorage.get(), 'PUT', {
                projectId,
                type: 'PROJECT',
            })
            if (res.status !== 200) {
                alert('Something went wrong')
            }
            navigate('/sessions')
        } catch (e) {
            setWorking(false)
        }
    }
    const timeout = useRef()
    const searchCallback = useCallback(async (q) => {
        if (timeout.current) {
            clearTimeout(timeout.current)
        }
        setSearch(q)
        timeout.current = setTimeout(async () => {
            const payload = await getProjects(authStorage.get(), false, false, 0, 100, q)
            setSkip(payload.nextTake)
            setProjects(payload.list)
        }, 500)
    }, [setSkip, setProjects])

    return (
        <div className="container">
            <div className="row">
                <div className="col-2 col-lg-1">
                    <div className="my-3">
                        <Link to={"/sessions"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                                 className="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
                            </svg>
                        </Link>
                    </div>
                </div>
                <div className={"col-10 col-lg-11 align-content-center"}>
                    <h1>Select a projects:</h1>
                </div>
            </div>
            <div className="row">
                <div className={"col-12"}>
                    <input type="search" className="form-control search-input my-2" placeholder="SUCHEN..."
                           value={search} onChange={e => {
                        searchCallback(e.target.value)
                    }}/>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                </div>
            </div>
            <div className="row justify-content-between">
                <div className="col-12">

                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Customer</th>
                                <th scope="col">-</th>
                            </tr>
                            </thead>
                            <tbody>
                            {projects.map(project => (
                                <tr key={project.number}>
                                    <td>{project.number}</td>
                                    <td>{project.name}</td>
                                    <td>{project.customer}</td>
                                    <td>
                                        <button className="btn btn-secondary btn-sm"
                                                disabled={working}
                                                onClick={() => select(project.id)}>Select
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            {isLeading && <tr key={"loading"}>
                                <td colSpan={5} className="text-center">
                                    Loading...
                                </td>
                            </tr>}
                            {projects.length > 0 && <tr key={"next"}>
                                <td colSpan={5}>
                                    <button className="btn btn-text d-block my-2 my-lg-3 m-auto loadMore"
                                            onClick={next}>Mehr
                                        laden
                                    </button>
                                </td>
                            </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SelectProject;